
            @import 'src/styles/mixins.scss';
        
.close-button {
    position: absolute;
    right: -1rem;

    @include lg {
        right: 1rem;
        top: 1rem;
    }
    z-index: 999;
}

.form {
    display: flex;
    flex-direction: column;
    @include lg {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        display: grid !important;
    }

    gap: 0 1rem;
    @include lg {
        padding: 50px;
    }
    position: relative;
    legend {
        padding: 1rem;
        margin-bottom: 10px;
    }
    .fieldWrapper {
        width: unset !important;
        // grid-column: 1/3;

        &:nth-child(3n + 1) {
            @include lg {
                grid-column: 1/3;
            }
        }

        .field {
            padding: 1rem !important;
            background-color: #e6e6e6 !important;
        }
    }
    div:last-child {
        grid-column: 1/3;
    }
}

.errorMessage {
    @apply text-left;
    font-size: 0.875rem !important;
    color: #f87171 !important;
}
.submitButton {
    @include button;
    background-color: #dd1f26;
    color: white;
    border-radius: 0 !important;
    margin: 1rem auto;
    white-space: nowrap;
    min-width: 220px;
    width: unset !important;
}

.thankYouStep{
    padding: 8rem 2rem;
}