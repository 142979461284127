
            @import 'src/styles/mixins.scss';
        
.button {
    @apply rounded text-white font-medium py-2 px-5 flex items-center justify-center cursor-pointer text-center;
    .inner {
        position: relative;
        transform-style: preserve-3d;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        width: 100%;
        @apply mx-auto block;
        .label {
            z-index: 3;
        }
        .icon {
            transform: translateX(-25px) translateY(-50%) translateZ(-1px);
            transition: transform 0.2s ease;
            // opacity: 0;
            position: absolute;
            top: 50%;
            right: -5px;
            svg {
                fill: white;

                @apply h-3 w-auto;
            }
            @apply ml-2;
        }
    }

    &:hover {
        .icon {
            transform: translateX(100%) translateY(-50%);
            opacity: 1;
        }
    }
}
