
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.contentStyle {
    background-color: white;
    // width: 30%;
    height: 100%;

    .btn {
        width: 100%;
        padding-block: 0.5rem;
        background-color: #1693f0;
        color: white;
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            background-color: #107fd4;
        }
    }
}
.closeIcon {
    background-color: white;
    border-radius: 100px 0 0 100px;
    width: 67px;
    height: 75px;
    display: none;
    @include lg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    padding-left: 10px;
    cursor: pointer;
    // border: 1px solid;
}
.fadeIn{
    animation: fadeIn ease .3s;
    -webkit-animation: fadeIn ease .3s;
    -moz-animation: fadeIn ease .3s;
    -o-animation: fadeIn ease .3s;
    -ms-animation: fadeIn ease .3s;
    transition: all .3 ease;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
.scale-in-center {
	animation: scale-in-center 0.250s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    transition: all .3 ease;
}

 @keyframes scale-in-center {
    0% {
      transform: scale(.9);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  