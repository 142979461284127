
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        height: 1.5px;

        background-color: #ebebeb;
        filter: drop-shadow(0px 2px 4.9px rgba(0, 0, 0, 0.15));
        top: 0;
        z-index: -1;
    }
}
