
            @import 'src/styles/mixins.scss';
        
.btnStyle {
    position: relative;
    transition: all 0.3s ease-in-out;
    background-color: #ef4f7f;
    overflow: hidden;
    &::before {
        content: "";
        background: rgba(white, 0.4);
        width: 60%;
        height: 100%;
        top: 0%;
        left: -125%;
        transform: skew(30deg);
        position: absolute;
        transition: left 0.4s ease-in-out;
    }
    &:hover {
        background-color: rgba(226, 148, 171, 0.98);
        &::before {
            left: 125%;
        }
    }
}
