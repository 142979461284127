
            @import 'src/styles/mixins.scss';
        
.header {
    color: white;
    .call-button {
        background-color: #dd1f26;
        @include button;
    }
}
.active {
    @include md {
        display: flex;
    }
    display: block;
}
@mixin underline {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        border-radius: 99px;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
}
.nav-link {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #ed1e2b;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
    &:hover {
        &:before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
}
.active-link {
    @include underline;
}