
            @import 'src/styles/mixins.scss';
        
.menuItem {
    color: #fff;
    position: absolute;
    right: 0;
    z-index: 10;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.3s ease-out;
    display: none;

    @include md {
        display: block;
    }
    .allMenu {
        display: none;
    }
    .innerDiv {
        padding: 7px 32px;
        border-radius: 50px;
        background-color: #351c4f;
        transition: all 0.3s ease-out;
    }
    &:hover {
        .innerDiv {
            padding: 40px 56px;
            transition: all 0.3s ease-out;
        }
        h3 {
            font-size: 30px;
            color: #867795;
            transition: color 1s ease-out;
        }
        .allMenu {
            display: block;
        }
    }
}
.menu {
    @media screen and (max-width: 1048px) {
        // display: none;
        position: fixed;
        right: 0;
        height: 100vh;
        width: 100%;
        background: #351c4f;
        z-index: 100;
        top: 0;
        transform: translate3d(1048px, 0, 0);
        transition: all 0.3s;
        color: #fff;
        font-size: 20px;
        text-align: center;
        padding-top: 130px;

        h3 {
            font-size: 30px;
            color: #867795;
            transition: color 1s ease-out;
        }
    }

    &.open {
        transform: translate3d(0, 0, 0);
        // display: block;
    }
}
.burger {
    width: 26px;
    height: 18px;
    // position: fixed;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 101;
    display: none;
    right: 30px;
    margin-top: 5px;
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.burger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #351c4f;
    border-radius: 9px;
    display: block;
    opacity: 1;

    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}
.openBurger span {
    background-color: #fff;
    left: 25px;
}
.burger span:nth-child(1) {
    top: 0px;
}

.burger span:nth-child(2) {
    top: 8px;
}

.burger span:nth-child(3) {
    top: 16px;
}

.burger.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.burger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.burger.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.stickyStyle {
    position: sticky;
    top: 0;
}
.stickyStyleAfter {
    background-color: #fff;
    padding: 1.1rem 16px 1.8rem 16px;
    box-shadow: 0px 10px 20px rgba(43, 61, 79, 0.06);
    z-index: 110;
    @include lg {
        padding: 1.8rem 0;
    }
}
