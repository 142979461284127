
            @import 'src/styles/mixins.scss';
        
.contact-us-form-wrapper {
    .form {
        @apply flex flex-wrap flex-col lg:flex-row justify-between h-full relative overflow-hidden w-full;
        .field-wrapper {
            margin: 5px 0;
            width: 100%;
            @apply flex flex-col;
            > label {
                margin-bottom: 0.5rem;
            }
            .field-error {
                font-size: 10px;
                color: red;
                font-weight: normal;
                margin-top: 5px;
                height: 18px;
            }
            .field {
                border: 1px solid grey;
                padding: 10px;
                &::placeholder {
                    font-weight: normal;
                }
                &:focus {
                    outline: none;
                }
            }
            @include lg {
                width: 47%;
            }
            // &:has(label) {
            //     width: 100%;
            //     @include lg {
            //         width: 100%;
            //     }
            // }
        }
    }
}
.custom-checkbox {
    display: none;
}
.custom-checkbox + div {
    display: none;
}

.checkboxLabel {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    font-weight: 300 !important;
    margin-bottom: 100px !important;
}

.checkboxLabel:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: white;
}

.checkboxLabelChecked:before {
    background-color: #2196f3;
}

.checkboxLabelChecked:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.select {
    background: transparent;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8' %3E%3Cpath fill='%236D7577' fillRule='evenodd' d='M6.482 2.964a.4.4 0 01.31.652l-2.797 3.44a.4.4 0 01-.62 0L.575 3.615a.4.4 0 01.31-.652h5.596z' clipRule='evenodd' %3E%3C/path%3E%3C/svg%3E");
    background-position-x: 94%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-right: 22px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    width: 100%;
    &::-ms-expand {
        display: none;
    }
    &.empty {
        color: rgba(black, 0.4);
        font-weight: 300;
    }
}

.checkboxGroup {
    display: flex;
    align-items: center;
    .option {
        text-align: center;
        position: relative;
        padding: 0 0.5rem;
        border-radius: 8px;
        input {
            visibility: hidden;
            user-select: none;
            position: absolute;
            left: -100rem;
            &:checked + label .box svg path {
                stroke-dashoffset: 0;
            }
            &:checked + label .box {
                background-color: var(--primary-color);
                border: 1px solid var(--primary-color);
            }
        }
        label {
            text-align: center;
            // width: 100%;
            display: block;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            display: inline-block;
            display: flex;
            align-items: center;
            .box {
                display: block;
                border: 1px solid #ffffff;
                width: 15px;
                height: 15px;
                border-radius: 2px;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-right: 5px;
                padding: 2px;

                svg {
                    pointer-events: none;
                    path {
                        fill: none;
                        stroke: white;
                        stroke-width: 6px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 101;
                        transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                    }
                }
            }
        }
    }
}

.selectFieldTitle {
    font-weight: 700;
    padding-bottom: 10px;
}

.multi-select {
    .multi-select-input-container {
        position: relative;
        background: #fff;
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8' %3E%3Cpath fill='%236D7577' fillRule='evenodd' d='M6.482 2.964a.4.4 0 01.31.652l-2.797 3.44a.4.4 0 01-.62 0L.575 3.615a.4.4 0 01.31-.652h5.596z' clipRule='evenodd' %3E%3C/path%3E%3C/svg%3E");
        background-position-x: 94%;
        background-position-y: 50%;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 10px 15px;
        padding-right: 22px;
        border: 1px solid #d0d0d0;
        min-height: 50px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 300;
        padding: 10px;

        &.active {
            color: black;
            font-weight: 400;
        }
    }

    .multi-select-tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        height: 100%;
        margin: 7px 30px 7px 0;

        .tag {
            @apply py-[5px] px-2 text-sm rounded-lg flex gap-3;
            background-color: #f2f2f2;
            color: #000;
        }

        .multi-select-input {
            margin: auto 0;
            &:focus {
                outline: none;
            }
            appearance: none;
            line-height: 1.8;
            font-size: 1em;
            outline: none;
            width: 0;
            max-width: 100%;
            flex-grow: 1;
            z-index: 1;
            overflow: visible;
            cursor: text;
            margin-right: 30px;
            white-space: normal;
        }
    }

    .multi-select-dropdown {
        border: 1px solid #d0d0d0;
        position: absolute;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #d0d0d0;
        max-height: 300px;
        overflow-y: scroll;
        > div {
            margin-bottom: 0 !important;
            label {
                border: none !important;
                border-bottom: 1px solid #d0d0d0 !important;
            }
        }

        .option {
            text-align: center;
            margin-bottom: 10px;
            width: 100%;
            position: relative;
            input {
                visibility: hidden;
                user-select: none;
                position: absolute;
                left: -100rem;
                &:checked + label .box svg path {
                    stroke-dashoffset: 0;
                    stroke: rgb(255, 255, 255);
                }
                &:checked + label .box {
                    background-color: var(--primary-color);
                }
            }
            label {
                display: flex;
                align-items: center;
                min-height: 46px;
                text-align: center;
                width: 100%;
                padding: 10px 20px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                .box {
                    display: block;
                    border: 1px solid var(--primary-color);
                    width: 15px;
                    height: 15px;
                    border-radius: 1px;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    margin-right: 15px;
                    padding: 2px;
                    background-color: white;

                    svg {
                        pointer-events: none;
                        path {
                            fill: none;
                            stroke: var(--primary-color);
                            stroke-width: 6px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-dasharray: 100;
                            stroke-dashoffset: 101;
                            transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                        }
                    }
                }

                .with-tooltip {
                    position: absolute;
                    background: #686565;
                    color: #fff !important;
                    padding: 3px 8px;
                    border-radius: 5px;
                    top: -10px;
                    left: 50%;
                    opacity: 0;
                    transition: opacity 0.1s;
                    white-space: nowrap;
                    font-size: 12px;
                    z-index: 4;
                    line-height: 1rem;
                    transform: translate(-50%, -100%);
                    &::after {
                        content: " ";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -5px;
                        border: 5px solid transparent;
                        border-top-color: #686565;
                    }
                }
                &:hover {
                    opacity: 1;
                    .with-tooltip {
                        opacity: 1;
                        display: unset;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
