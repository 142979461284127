
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        top: -45px;
        height: calc(100% + 45px);
        @include md {
            top: -131px;
            height: calc(100% + 131px);
        }
        position: absolute;
        background-color: #f7f2fb;
        z-index: -10;
    }
}
